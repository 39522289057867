<template>
  <div class="home" ref="homeBox">
    <div v-if="isMobile" class="phoneProductBox">
      <div class="concentBox">
        <div class="banner" v-if="isBanner==1">
          <img :src="constUrl+banner"/>
        </div>
        <div class="banner" v-else-if="isBanner==2">
          <el-carousel height="150px" arrow="never" indicator-position="none">
            <el-carousel-item v-for="item in childPic" :key="item">
              <h3 class="small2 small" style="font-size: 20px;background: transparent;">
                <el-image
                  style=" height: 150px"
                  :src="constUrl+item"
                  fit="fill"></el-image>
                <!--                <img :src="constUrl+item" style="width: 100vh;height: 100vw"/>-->
              </h3>
            </el-carousel-item>
          </el-carousel>
        </div>
         <div class="menuBox">
           <el-menu
             :default-active="activeIndex2"
             id="navBox"
             :class="menuTop==1?'menuTop':'el-menu-demo'"
             mode="horizontal"
             background-color="#545c64"
             text-color="#fff"
             active-text-color="#ffd04b">
             <el-menu-item :index="0+''" @click="toList()">
               所有产品
             </el-menu-item>
             <el-submenu :index="item.id+''" v-for="(item,a) in dataList" :key="item.id">
               <template slot="title"> {{item.name}}</template>
               <el-menu-item :index="(a+1)+''" @click="toList(item.id)">
                 {{item.name}}
               </el-menu-item>
               <div v-for="child in item.itemList" :key="child.id">
                 <el-menu-item :index="child.id+''" @click="toDetails(child.id)">
                   {{child.name}}
                 </el-menu-item>
               </div>
             </el-submenu>
           </el-menu>
         </div>
        <router-view class="routerBox" :dataList="dataList" :id="id" @topPic="topPic"
                     @showBanner="showBanner"></router-view>
      </div>
      <!-- 查看大图 -->
      <picture-viewer ref="imageViewer"></picture-viewer>
    </div>
    <footer-box v-if="isMobile"   class="footerBoxAll" style="z-index: 999" ></footer-box>

    <!--      pc-->
    <div v-else class="pcProductBox">
      <div class="concentBox">
        <div class="banner" v-if="isBanner==1">
          <img :src="constUrl+banner" style="height: 100%"/>
        </div>
        <div class="banner" v-else-if="isBanner==2">
          <el-carousel height="550px" arrow="never" indicator-position="none">
            <el-carousel-item v-for="item in childPic" :key="item">
              <h3 class="small2 small" style="font-size: 20px;background: transparent;">
                <el-image
                  style=" height: 550px"
                  :src="constUrl+item"
                  fit="fill"></el-image>
                <!--                <img :src="constUrl+item" style="width: 100vh;height: 100vw"/>-->
              </h3>
            </el-carousel-item>
          </el-carousel>
        </div>
        <el-menu
          :default-active="activeIndex2"
          id="navBox"
          :class="menuTop==1?'menuTop':'el-menu-demo'"
          mode="horizontal"
          background-color="#545c64"
          text-color="#fff"
          active-text-color="#ffd04b">
          <el-menu-item :index="0+''" @click="toList()">
            所有产品
          </el-menu-item>
          <el-submenu :index="item.id+''" v-for="(item,a) in dataList" :key="item.id">
            <template slot="title"> {{item.name}}</template>
            <el-menu-item :index="(a+1)+''" @click="toList(item.id)">
              {{item.name}}
            </el-menu-item>
            <div v-for="child in item.itemList" :key="child.id">
              <el-menu-item :index="child.id+''" @click="toDetails(child.id)">
                {{child.name}}
              </el-menu-item>
            </div>
          </el-submenu>
        </el-menu>
        <router-view class="routerBox" :dataList="dataList" :id="id" @topPic="topPic"
                     @showBanner="showBanner"></router-view>
      </div>
      <!-- 查看大图 -->
      <picture-viewer ref="imageViewer"></picture-viewer>
    </div>
  </div>

</template>
<script>
  import PictureViewer from '@/components/picture-viewer'
  import 'swiper/dist/css/swiper.min.css'
  import ItemMixins from "../item.mixins"; // ES6 导入swiper css文件
  export default {
    mixins: [ItemMixins],
    components: {PictureViewer},
    data() {
      return {
        isLoading: false,
        menuTop: 0,
        activeIndex2: '0',
        dataList: [],
        id: '',
        isBanner: 1,
        banner: '',
        childPic: []
      }
    },
    mounted() {
      window.addEventListener('scroll', this.handleScrolls, true)
      this.init()
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScrolls, true)
    },
    activated() {
    },
    methods: {
      async init() {
        let item = this.getActiveItem()
        this.banner = item.picture[0]
        await this.getHeadMenuList()
        this.dataList = await this.getChildList()
      },
      showBanner(val) {
        this.isBanner = val
      },
      topPic(val) {
        if (val) {
          this.childPic = val
        }
        this.isBanner = 2
      },
      toDetails(id) {
        this.$router.push({
          path: `/product/detail/${id}`,
        })
      },
      // 去所有产品列表页面
      toList(id) {
        if (id == undefined) {
          this.id = ''
        } else {
          this.id = id
        }
        this.isBanner = 1
        this.$router.push('/productAll');
      },
      handleScrolls() {
        // 页面滑动的距离
        // return
        let scrollTop = document.querySelector('.routerBox').getBoundingClientRect().top
        // 当页面滑动的距离小于80px时元素不显示，否则显示
        if (scrollTop <= 80) {
          this.$emit('showNav', false)
          this.menuTop = 1
        } else if (scrollTop > 80) {
          this.$emit('showNav', true)
          this.menuTop = 0
        }
      },
    },
  }
</script>
<style scoped lang="less">
  .home {
    width: 100%;
    /*height: 100%;*/
    overflow: auto;
  }

  .menuTop {
    position: fixed;
    top: 0;
    z-index: 999;
    width: 100%;
    margin-top: auto;
    text-align: center;
    box-sizing: border-box;
    padding-left: 30%;

  }
  .concentBox {
    width: 100%;
    .small2 {
      width: 100%;
      height: 100%;
      background: white;
      display: flex;
      justify-content: center;

      img {
        width: 80% !important;
        height: 100% !important;

      }
    }
  }

  /*设置banner*/
  .banner {
    width: 100vw;
    height: 550px;
  }

  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 150px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    /*background-color: #99a9bf;*/
  }

  .el-carousel__item:nth-child(2n+1) {
    /*background-color: #d3dce6;*/
  }

  /*  设置切换*/
  .el-menu-demo {
    text-align: center;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .routerBox {
    width: 90%;
    height: 100%;
    border: 1px solid transparent;
    box-sizing: border-box;
    padding: 2vh 0px;
    padding-top: 0px;
  }

  a:hover, a:link, a:visited, a:active, a:focus {
    color: ghostwhite;
    text-decoration: none;

  }

  a:hover, a:active {
    color: rgb(255, 208, 75)
  }
</style>
<!--手机端样式-->
<style scoped lang="less">
  .home {
    width: 100vw;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    .footerBoxAll{
      position: absolute !important;
      bottom: 0;

    }
  }

  .phoneProductBox {
    width: 100vw;
    height: 100vh;

    .menuTop {
      position: fixed;
      top: 0;
      z-index: 999;
      width: 100%;
      margin-top: auto;
      text-align: center;
      box-sizing: border-box;
      padding-left: 1%;

      li{
        font-size: 2vw !important;

        width: 15% !important;
      }
      .el-submenu{
        font-size: 2vw !important;

      }
    }

    .concentBox {
      width: 100%;

      .small2 {
        width: 100%;
        height: 100%;
        background: white;
        display: flex;
        justify-content: center;

        img {
          width: 80% !important;
          height: 100% !important;

        }
      }
    }

    /*设置banner*/
    /deep/.el-submenu{
      background: gold !important;
    }
    /deep/.el-submenu__title{
      font-size: 10px !important;
    }
    .banner {
      width: 100vw;
      height: 100%;

      img {
        /*height: 100%;*/
      }
    }

    .el-carousel__item h3 {
      background: red;
      color: #475669;
      font-size: 1vw !important;
      opacity: 0.75;
      line-height: 150px;
      margin: 0;
    }

    .el-carousel__item:nth-child(2n) {
      /*background-color: #99a9bf;*/
    }

    .el-carousel__item:nth-child(2n+1) {
      /*background-color: #d3dce6;*/
    }

    /*  设置切换*/

    .el-menu-demo {
      text-align: center;
      margin: auto;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .routerBox {
      width: 90%;
      height: 100%;
      border: 1px solid transparent;
      box-sizing: border-box;
      padding: 2vh 0px;
      padding-top: 0px;
    }

    a:hover, a:link, a:visited, a:active, a:focus {
      color: ghostwhite;
      text-decoration: none;

    }

    a:hover, a:active {
      color: rgb(255, 208, 75)
    }
  }

</style>
